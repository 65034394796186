<template>
  <h2>會員管理</h2>

  <div class="my-4 text-end">
    <router-link :to="`/admin/member`">返回列表頁</router-link>
  </div>

  <form action="" @submit.prevent="insertMember()">
    <div class="card">
      <div class="card-header">
        <div class="h5 d-inline mb-0">新增會員</div>
        <button type="reset" class="btn btn-primary float-end">清除重填</button>
      </div>
      <div class="card-body">
        <!-- <pre>
          {{ data }}
        </pre> -->
        <div class="row">
          <!-- <div class="col-md-6">??</div>
              <div class="col-md-6">??</div> -->

          <!-- <div class="col-md-12">
            <div class="mb-4">
              <label class="form-label">經紀人身份</label>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexCheckChecked"
                  v-model="data.agent"
                />
                <label class="form-check-label" for="flexCheckChecked">
                  是經紀人
                </label>
              </div>
            </div>
          </div> -->

          <div class="col-md-12">
            <div class="mb-4">
              <label class="form-label">身份</label>
              <select
                class="form-select w-25"
                v-model="data.role"
                @change="changeRole()"
              >
                <option value="generally">一般會員</option>
                <option value="agent">經紀人</option>
                <option value="agentCompanyA">合作經紀公司 A</option>
                <option value="agentCompanyB">合作經紀公司 B</option>
              </select>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label"> 暱稱 </label>
              <input type="text" class="form-control" v-model="data.name" />
            </div>
          </div>
          <!-- <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label"
                >會員編號 (測試用, 暫時先自己用打的)</label
              >
              <div class="bg-light px-3 py-2 rounded" role="alert">
                {{ memberId }}
              </div>
            </div>
          </div> -->
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label"> 上線會員 </label>
              <Multiselect
                v-model="data.parentMemberId"
                mode="single"
                :searchable="true"
                :options="parentMemberOption"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">生日</label>
              <input type="date" class="form-control" v-model="data.birthday" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">
                <span class="text-danger">*</span>
                手機號碼 (格式請以 +8869xxxxxxxx)
              </label>
              <input type="text" class="form-control" v-model="data.mobile" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">電子信箱</label>
              <input type="email" class="form-control" v-model="data.email" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">服務店家</label>
              <!-- <input type="text" class="form-control" v-model="data.store" /> -->
              <select class="form-select" v-model="data.store">
                <option value="">--無--</option>
                <option v-for="(store, idx) in storeOptions" :key="idx">
                  {{ store }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">編號藝名</label>
              <input
                type="text"
                class="form-control"
                v-model="data.numberStageName"
              />
            </div>
          </div>
        </div>
        <div class="text-center mt-5">
          <button type="submit" class="btn btn-success btn-lg">新增</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { settingService } from "../../../services/settingService.js";
import { memberService } from "../../../services/memberService.js";
import { variableService } from "../../../services/variableService.js";
import Multiselect from "@vueform/multiselect";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      parentMemberOption: [],
      memberId: "",
      data: {
        // name: "",
        status: "normal",
        role: "generally",
        agent: false,
        // member_id: "",
        // authUid: "",
        // parentMemberId: "",
        // nextLevel1MembersId: [],
        // birthday: "",
        mobile: "+886",
        store: "",
        // email: "",
        // registerTime: "",
        // Level1bonuspercentage: 0,
        // Level2bonuspercentage: 0,
        // Level3bonuspercentage: 0,
        // selfBonusPercentage: 0,
      },
      storeOptions: [],
    };
  },
  methods: {
    changeRole() {
      if (this.data.role === "agent") {
        this.data.agent = true;
      } else {
        this.data.agent = false;
      }
    },
    async insertMember() {
      // 判斷服務店家是否存在
      if (
        (await settingService.checkInarrayStores(this.data.store)) === false
      ) {
        alert("服務店家不存在");
        return false;
      }

      const result = await memberService.insertMember(this.memberId, this.data);

      await variableService.init_allMember();

      alert(result.msg);

      if (result.res) {
        this.$router.push({ name: "member" });
      }
    },
  },
  async created() {
    // 設定註冊時間
    const now = new Date();
    // const y = now.getFullYear();
    // const m = ("0" + now.getMonth()).slice(-2);
    // const d = ("0" + now.getDate()).slice(-2);
    // const h = ("0" + now.getHours()).slice(-2);
    // const min = ("0" + now.getMinutes()).slice(-2);
    // const s = ("0" + now.getSeconds()).slice(-2);
    // this.data.registerTime = `${y}/${m}/${d} ${h}:${min}:${s}`;

    this.data.registerTime = new Date();

    // this.memberId = "admin-test-" + Math.random().toString(36).substring(7);
    this.memberId = String(now.getTime());

    // 取得會員名單
    this.parentMemberOption =
      await memberService.getMembersSelectOptions_withOutOne();

    // console.log(this.parentMemberOption);

    // 服務店家清單
    this.storeOptions = await settingService.getStores();
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
